.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 88px;
	padding: 88px 56px;
	background: #E2F2FF;

	.title {
		font-size: 48px;
		line-height: 55px;
		letter-spacing: 1.2px;
		text-align: center;
	}

	.cards {
		display: flex;
		flex-direction: row;
		gap: 24px;

		.cardItem {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 427px;
			height: 421px;
			border-radius: 40px;
			padding: 40px 29px 35px;
	
			.metric {
				font-size: 80px;
				font-weight: 400;
				letter-spacing: 1.5px;
				text-align: center;				
			}
	
			.mainText {
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;    
				justify-content: center;
	
				.cardTitle {
					font-size: 34px;
					font-weight: 400;
					line-height: 34px;
					letter-spacing: 1px;
					text-align: center;
				}
		
				.description {
					font-size: 24px;
					font-weight: 400;
					line-height: 30px;
					letter-spacing: 0.8px;
					text-align: center;		
				}
			}
	
			.source {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: -0.5px;
				text-align: center;	
				margin-top: 14px;		
			}
		}
	}
}

.mainColor {
	color: #468EFF;
}

.desktop {
	display: flex;
}

.mobile {
	display: none;
}

@media only screen and (max-width: 768px) {
	.container {
		gap: 32px;
		padding: 40px 20px;

		.title {
			font-size: 30px;
			line-height: 110%;
			letter-spacing: 0.6px;
			text-align: center;
		}

		.cards {
			flex-direction: column;

			.cardItem {
				width: 311px;
				height: 296px;
				border-radius: 40px;
				padding: 32px;
	
				.metric {
					font-size: 48px;
					line-height: 55px;
					letter-spacing: 1.2px;
				}
	
				.mainText {
					.cardTitle {
						font-size: 20px;
						line-height: 25px;
						letter-spacing: 0.6px;
					}
					
					.description {
						font-size: 20px;
						line-height: 25px;
						letter-spacing: 0.6px;
					}
					
				}
	
				.source {
					margin-top: 4px;		
				}			
			}
		}
	}

	.desktop {
		display: none;
	}
	
	.mobile {
		display: flex;
	}
}